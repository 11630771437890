import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["topbar", "content", "search_input", "search_form", "sidebar_container",
                    "sidebar_close", "sidebar_panel", "sidebar_backdrop",
                    "form_modal", "import_modal", "upload_file_count", "comment_content", "desk_sidebar_panel",
                    "desktop_body", "desk_sidebar_container", "desk_side_open_btn"]

  connect() {
    let progress_barTarget = document.getElementById('progress_bar');
    console.log("stimulus-connected: common_controller.js")
    console.log("stimulus-connected: progress_barTarget", progress_barTarget)
    document.addEventListener('turbo:load', () => {
      console.log('Page has loaded via Turbo!');
    });
    document.addEventListener('turbo:submit-start', () => {
      console.log('TURBO: SUBMIT-START');
      progress_barTarget.classList.remove('hidden');
      progress_barTarget.classList.add('w-[20%]');
    });

    document.addEventListener('turbo:submit-end', () => {
      console.log('TURBO: SUBMIT-END');
      progress_barTarget.classList.add('w-[100%]');
      progress_barTarget.classList.add('hidden');
    });
    document.addEventListener('turbo:before-fetch-request', () => {
      console.log('TURBO: BEFORE FETCH REQUEST');
      progress_barTarget.classList.remove('hidden');
      progress_barTarget.classList.add('w-[20%]');
    });
    document.addEventListener('turbo:before-fetch-response', () => {
      console.log('TURBO: BEFORE FETCH RESPONSE');
      progress_barTarget.classList.remove('hidden');
      progress_barTarget.classList.add('w-[50%]');
    });
    document.addEventListener('turbo:before-cache', () => {
      console.log('TURBO: BEFORE CACHE');
      progress_barTarget.classList.add('w-[100%]');
      progress_barTarget.classList.add('hidden');
    });

  }

  clearContent() {
    console.log("clearContent called");
    this.comment_contentTarget.value = '';
  }

  adjust_padding() {
    const topbarHeight = this.topbarTarget.offsetHeight
    this.contentTarget.style.paddingTop = `${topbarHeight}px`
  }

  submit_search_form(event) {
    console.log("Search link clicked");
    var element = event.currentTarget;
    if(element.dataset.search) {
      this.search_inputTarget.value = element.dataset.search;
    }
    console.log(element, this.search_inputTarget.value);
    this.search_formTarget.submit();
  }

  open_sidebar() {
    this.sidebar_containerTarget.classList.remove("hidden");
    enter(this.sidebar_backdropTarget);
    enter(this.sidebar_closeTarget);
    enter(this.sidebar_panelTarget);
  }

  hide_sidebar() {
    console.log("HIDE SIDEBAR called")
    Promise.all([
      leave(this.sidebar_backdropTarget),
      leave(this.sidebar_closeTarget),
      leave(this.sidebar_panelTarget),
    ]).then(() => {
      this.sidebar_containerTarget.classList.add("hidden");
    });
  }

  hide_bigger_sidebar() {
    console.log("HIDE SIDEBAR called", this.desk_sidebar_panelTarget)
    Promise.all([
      leave(this.desk_sidebar_panelTarget),
    ]).then(() => {
      this.desktop_bodyTarget.classList.remove("lg:pl-56");
      this.desk_sidebar_containerTarget.classList.add("hidden");
      document.getElementById("desk_side_open_btn").classList.remove("hidden");
    });
  }

  desk_open_sidebar() {
    document.getElementById("desk_side_open_btn").classList.add("hidden");
    this.desk_sidebar_containerTarget.classList.remove("hidden");
    this.desktop_bodyTarget.classList.add("lg:pl-56");
    enter(this.desk_sidebar_panelTarget);
  }


  show_import_modal() {
    this.import_modalTarget.classList.remove('hidden')
  }

  hide_import_modal() {
    this.import_modalTarget.classList.add('hidden')
  }

  show_form_modal() {
    console.log("stimulus-connected: modalTarget", this.modalTarget)
    this.form_modalTarget.classList.remove('hidden')
  }

  show_modal_with_id(event) {
    var element = event.currentTarget;
    var modal_id = element.dataset.modal;
    console.log("show_modal_with_id: ", modal_id)
    document.getElementById(modal_id).classList.remove('hidden')
    enter(document.getElementById("backdrop_modal_"+modal_id));
    enter(document.getElementById("bottom_modal_"+modal_id));
  }

  hide_modal_with_id(event) {
    var element = event.currentTarget;
    var modal_id = element.dataset.modal;
    Promise.all([
      leave(document.getElementById("backdrop_modal_"+modal_id)),
      leave(document.getElementById("bottom_modal_"+modal_id))
    ]).then(() => {
      document.getElementById(modal_id).classList.add('hidden');
    });
  }

  hide_form_modal() {
    this.form_modalTarget.classList.add('hidden')
  }

  upload_file(event) {
    let element = event.currentTarget
    console.log("upload_file: ", element.files.length)
    if(element.files.length > 0) {
      this.upload_file_countTarget.innerHTML = element.files.length + ' files selected'
      this.upload_file_countTarget.classList.remove('text-gray-500')
      this.upload_file_countTarget.classList.add('text-green-500')
    }
    else {
      this.upload_file_countTarget.innerHTML = 'no file selected'
      this.upload_file_countTarget.classList.remove('text-green-500')
      this.upload_file_countTarget.classList.add('text-gray-500')
    }
  }

  check_revert_text(event) {
    var element = event.currentTarget;
    var data = element.value;
    var element_id = element.id
    if(element_id.includes("revert_activity")){
      var formKey = element_id.split('_').pop();
      console.log(formKey);
      var saveForm = document.getElementById(`revert_form_${formKey}`);
      var saveButton = document.getElementById(`revert_confirm_button_${formKey}`);
      if(data == "revert"){
        saveButton.disabled = false;
        saveForm.disabled = false;
      }
      else{
        saveButton.disabled = true;
        saveForm.disabled = true;
      }
      return;
    }
    var modal_id = element.id.split('_')[1];
    var confirmButton = document.getElementById('revert_confirm_button_'+modal_id);
    var revertForm = document.getElementById('revert_form_'+modal_id);
    if(data == "revert"){
      confirmButton.disabled = false;
      revertForm.disabled = false;
    }
    else{
      confirmButton.disabled = true;
      revertForm.disabled = true;
    }
  }
}
