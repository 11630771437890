import { Controller } from "@hotwired/stimulus"
// import Quagga from 'quagga';
import { Html5QrcodeScanner, Html5QrcodeScanType } from "html5-qrcode";

export default class extends Controller {
  static targets = [ "submit_button", "submit_button_text" ]
  connect() {
    this.scannerActive = false; // Track whether the scanner is active

    // Set up Quagga configuration
    this.config = {
      inputStream: {
        name: 'Live',
        type: 'LiveStream',
        target: document.querySelector('#scanner'),
      },
      locator: {
        patchSize: 'small',
        halfSample: true,
      },
      decoder: {
        readers: ['code_128_reader']
      },
      numOfWorkers: navigator.hardwareConcurrency,
      locate: true,
    };

    this.configQr = {
      fps: 10,
      qrbox: {width: 400, height: 600},
      rememberLastUsedCamera: true,
      // Only support camera scan type.
      supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA]
    };

    this.numOfWorkers = navigator.hardwareConcurrency;

    this.html5QrcodeScanner = null;
  }

  disconnect() {
    this.stopScanner();
  }

  startScanner() {
    document.getElementById('qrcode_scanner').classList.remove('hidden')
    console.log("start_scanner called")
    if (!this.scannerActive) {
      this.scannerActive = true;
      this.numOfWorkers = Math.max(2, this.numOfWorkers);

      // Below code is commented as barcode scanner is not used now

      // Quagga.init(this.config, (err) => {
      //   if (err) {
      //     console.error(err);
      //     return;
      //   }
      //   Quagga.start();
      // });
      // Quagga.onDetected(this.handleBarcodeDetection);

      let codeScanner = new Html5QrcodeScanner(
        "scanner", this.configQr, /* verbose= */ false);
      this.html5QrcodeScanner = codeScanner;
      this.html5QrcodeScanner.render(this.onScanSuccess, this.onScanError)
    }
  }

  stopScanner() {
    document.querySelector("#invalid-qrcode-message").classList.add("hidden");
    document.getElementById('qrcode_scanner').classList.add('hidden')
    if (this.scannerActive) {
      this.scannerActive = false;
      // Quagga.offDetected(this.handleBarcodeDetection);
      // Quagga.stop();
      this.html5QrcodeScanner.clear();
    }
  }

  removeUnsafeElements(str) {
    return str.replace(/[^\w\s-]/gi, ""); // Replace any characters that are not word characters, spaces, or hyphens
  }

  onScanSuccess = (decodedText, decodedResult) => {
    const result = decodedText;
    console.log("handleQrcodeDetection called - for - ", result);
    const order_id = this.removeUnsafeElements(result);
    if (order_id.length < 6) {
      return false;
    }
    var url = "/jobs/"+order_id+"/check_order_id_validity";
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      console.log("VERIFICATION DATA", data);
      if(data.valid) {
        console.log(`Detected qrcode: ${order_id}`);
        this.submit_button_textTarget.innerHTML = order_id
        this.submit_buttonTarget.disabled = false;
        this.submit_buttonTarget.setAttribute("data-search", order_id);
        this.stopScanner();
        this.submit_buttonTarget.click();
      } else {
        this.submit_buttonTarget.disabled = true;
        this.submit_button_textTarget.innerHTML = "No valid Qr Code detected"
        document.querySelector("#invalid-qrcode-message").classList.remove("hidden");
      }
    })
    .catch(error => {
      console.log(error);
      this.submit_buttonTarget.disabled = true;
      this.submit_button_textTarget.innerHTML = "No valid qrcode detected"
    });
  };

  onScanError = (error) => {
    
  }
}
