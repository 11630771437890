import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["list"]

  refresh() {
    /* Use Turbo Frames to update the activity list */
    Turbo.visit(location.toString(), { action: "replace" })
  }
}
