import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["single_order_select", "select", "sizes", "parentDiv", "fabrics", "size_offered"]

  connect() {
    console.log("stimulus-connected: product_controller.js")
  }

  clear_select() {
    while(this.single_order_selectTarget.firstChild){
      this.single_order_selectTarget.removeChild(this.single_order_selectTarget.firstChild);
    }
    this.populate_select(['please select a size'])
  }

  populate_select(data, uppercase = false) {
    data.forEach((size) => {
      let option = document.createElement('option')
      option.text = uppercase ? size.toUpperCase() : size
      option.value = uppercase ? size.toUpperCase() : size
      option.className = 'text-gray-700 text-sm font-light uppercase'
      this.single_order_selectTarget.appendChild(option)
    })
  }

  get_sizes() {
    if(this.selectTarget.dataset.job == 'SingleOrder') {
      this.clear_select()
      this.single_order_selectTarget.disabled = true;
      const productId = this.selectTarget.value;
      if(productId.length == 0)
        return;
      fetch(`/products/${productId}/sizes.json`)
        .then(response => response.json())
        .then(sizes => {
          this.populate_select(sizes, true);
          this.single_order_selectTarget.disabled = false;
        })
      return;
    }
    if(this.selectTarget.value == "") {
      this.sizesTarget.innerHTML = ''
      this.parentDivTarget.classList.add('hidden')
      return;
    }
    const productId = this.selectTarget.value
    fetch(`/products/${productId}/sizes.json`)
      .then(response => response.json())
      .then(sizes => {
        let sizeFields = ''
        sizes.forEach(size => {
          this.sizesTarget.innerHTML = ''
          sizeFields += `
            <div class="h-full col-span-1 p-1 block text-base font-light text-gray-700">
              <div  class="p-1 inline-flex justify-center bg-blue-700
                  opacity-70
                  border border-transparent rounded-full
                  focus:outline-none
                  font-medium
                  ring-1 ring-offset-1 ring-blue-900
                  text-white text-xs">
                <span class="inline-flex px-1 uppercase">${size} </span>
              </div>
            </div>
            <input type="number" min="0" data-task-target="task_form_input"
              data-action="keyup->task#check_validity"
              placeholder="enter quantity"
              class="col-span-4 block
              w-full rounded-lg border-0
              p-1 text-gray-700 shadow-sm
              ring-1 ring-inset ring-gray-300
              placeholder:text-gray-400 focus:ring-2
              placeholder:font-light
              focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              required
              name="quantity[${size}]">
          `
        })
        this.sizesTarget.innerHTML += sizeFields
        this.parentDivTarget.classList.remove('hidden')
      })
  }

  get_fab_size_matrix() {
    var fab_ids_required = this.fabricsTarget.value.split(',')
      .map(element => (typeof element === 'string' ? element.trim() : element))
      .filter(element => element && element.length > 0);

    var sizes_offered = this.size_offeredTarget.value.split(',')
      .map(element => (typeof element === 'string' ? element.trim() : element))
      .filter(element => element && element.length > 0);

    console.log("FABRIC IDS REQUIRED: ", fab_ids_required);
    console.log("SIZES OFFERED: ", sizes_offered);
    if(fab_ids_required.length == 0 || sizes_offered.length == 0) {
      this.parentDivTarget.innerHTML = ''
      this.parentDivTarget.classList.add('hidden')
      return;
    }
    var parentDivField = '';
    sizes_offered.forEach(size => {
      parentDivField += `
        <div  class="bg-indigo-100 rounded-2xl drop-shadow p-2">
          <div class="bg-red-100 h-full w-fill text-left p-2
            block text-base text-gray-700
            rounded-2xl drop-shadow">
            <label class="self-center ml-1">
              Enter fabric quantities required for
                <span class="text-emerald-500 font-semibold uppercase"> ${size} </span> size (approx)
            </label>
          </div>
          <div data-product-target="sizes" class="w-full grid grid-cols-5 gap-2 p-2">`;
            fab_ids_required.map(fab_id => {
              parentDivField +=`
                <div class="h-full col-span-1 p-1 block text-base font-light text-gray-700">
                  <div  class="p-1 inline-flex justify-center bg-blue-700
                      opacity-70
                      border border-transparent rounded-full
                      focus:outline-none
                      font-medium
                      ring-1 ring-offset-1 ring-blue-900
                      text-white text-xs">
                    <span class="inline-flex px-1 uppercase">${fab_id} </span>
                  </div>
                </div>
                <input required type="number"
                  placeholder="enter quantity" class="col-span-3 block
                  w-full rounded-lg border-0
                  p-1 text-gray-700 shadow-sm
                  ring-1 ring-inset ring-gray-300
                  placeholder:text-gray-400 focus:ring-2
                  placeholder:font-light
                  focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" type="text"
                  name="details[${size}][${fab_id}]"
                  min=0>
                  <label class="self-center text-gray-700 text-sm">
                    meters
                  </label>
                `;
            })
            parentDivField += `
          </div>
        </div>
      `;
    });
    this.parentDivTarget.innerHTML = parentDivField;
    this.parentDivTarget.classList.remove('hidden')
  }

  get_fabric_array(fabric) {
    fabric.split(',')
  }

  get_size_offered() {
    this.size_offeredTarget.value.split(',')
  }
}
