import { Controller } from "@hotwired/stimulus"
import { leave } from "el-transition";
import pica from "pica";

export default class extends Controller {
  static targets = ["preview", "inputDiv", "fileName", "fileInput", "commentInputDiv", "commentFileInput"]

  connect() {
    console.log("stimulus-connected: form_controller connected")
    this.submit_form = false;
    this.modal_id = null;
  }

  comments_upload_file(event) {
    console.log("comment upload_file called");
    const name_field = this.previewTarget.dataset.name;
    const emptyInput = this.commentFileInputTargets.find(
      (input) => !input.files.length
    );
    if (emptyInput) {
      emptyInput.click();
    } else {
      const newInput = document.createElement("input");
      newInput.setAttribute("type", "file");
      newInput.setAttribute("data-form-target", "commentFileInput");
      newInput.setAttribute("data-action", "change->form#resizeImage form#displayCommentFileDiv");
      newInput.setAttribute("name", name_field);
      newInput.setAttribute("accept", "*");
      newInput.style.display = 'none';
      this.commentInputDivTarget.appendChild(newInput);
      newInput.click();
    }
  }

  remove_all_files(event) {
    this.commentInputDivTarget.innerHTML = '';
    this.previewTarget.innerHTML = '';
  }

  displayCommentFileDiv(event) {
    const file = event.currentTarget.files[0];
    if (file) {
      const file_size = (file.size / (1024 * 1024)).toFixed(2);
      const fileNameDiv = document.createElement("div");
      fileNameDiv.setAttribute("class", "px-1 py-0.5 w-fit border border-indigo-700/80 rounded-lg shadow-sm");
      fileNameDiv.setAttribute("data-form-target", "fileName");
      fileNameDiv.innerHTML = `
        <div class="w-full h-full flex flex-row gap-2">
          <svg class="self-center text-indigo-700/80 w-6"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clip-rule="evenodd" />
          </svg>
          <span class="w-20 xl:w-full self-center text-sm text-indigo-700/80 font-medium truncate ...">${file.name.substring(0, 10)} ${file.name.length > 10 ? '...' : ''}</span>
          <div class="self-center cursor-pointer" data-action="click->form#removeFile">
            <svg class="self-center text-indigo-700/80 w-5" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </div>
      `;
      this.previewTarget.appendChild(fileNameDiv);
    }
  }

  upload_file(event) {
    console.log("upload_file called");
    const allowed_ext = this.previewTarget.dataset.allowedext;
    const name_field = this.previewTarget.dataset.name;
    const is_required = this.previewTarget.dataset.required;
    const emptyInput = this.fileInputTargets.find(
      (input) => !input.files.length
    );
    if (emptyInput) {
      emptyInput.click();
    } else {
      const newInput = document.createElement("input");
      newInput.setAttribute("type", "file");
      newInput.setAttribute("data-form-target", "fileInput");
      newInput.setAttribute("data-action", "change->form#resizeImage form#displayFileDiv");
      newInput.setAttribute("name", name_field);
      newInput.setAttribute("accept", allowed_ext);
      if (is_required == "true") {
        newInput.setAttribute("required", "true");
      }
      newInput.style.display = 'none';
      this.inputDivTarget.appendChild(newInput);
      newInput.click();
    }
  }

  resizeImage(event) {
    console.log("IMAGE RESIZE CALLED called");
    const file = event.target.files[0];
    if (file && file.type.match('image.*')) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = 800;  // Set the desired width
          canvas.height = canvas.width * (img.height / img.width);

          // Resize the image using Pica
          pica().resize(img, canvas)
            .then(result => {
              // Convert the resized image to a blob
              return pica().toBlob(result, 'image/jpeg', 0.95);
            })
            .then(blob => {
              // Now you have a blob you can upload
              console.log('IMAGE RESIZED', blob);
            })
            .catch(error => {
              console.error('Resize error:', error);
            });
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  displayFileDiv(event) {
    const file = event.currentTarget.files[0];
    if (file) {
      const file_size = (file.size / (1024 * 1024)).toFixed(2);
      const fileNameDiv = document.createElement("div");
      fileNameDiv.setAttribute("class", "p-2 w-full border border-indigo-700/80 rounded-xl shadow-sm");
      fileNameDiv.setAttribute("data-form-target", "fileName");
      fileNameDiv.innerHTML = `
        <div class="w-full h-full flex flex-row justify-between gap-2">
          <div class="flex flex-row gap-2">
            <div class="flex bg-indigo-700/80 px-0.5 border border-gray-200 shadow-sm h-full rounded-lg">
              <svg class="self-center text-white h-8 w-8"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="flex flex-col justify-evenly truncate ...">
              <span class="w-20 sm:w-28 lg:w-48 xl:w-full self-start text-sm text-indigo-700/80 font-medium truncate ...">${file.name.substring(0, 10)} ${file.name.length > 10 ? '...' : ''}</span>
              <span class="self-start text-xs text-gray-400">${file_size} MB</span>
            </div>
          </div>
          <div class="self-center cursor-pointer" data-action="click->form#removeFile">
            <svg class="self-center text-indigo-700/80 h-8 w-8" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </div>
      `;
      this.previewTarget.classList.add("px-2", "py-2", "gap-1");
      this.previewTarget.appendChild(fileNameDiv);
      this.check_validity(event);
    }
  }

  removeFile(event) {
    const fileNameDiv = event.currentTarget.parentElement.parentElement;
    console.log("removeFile", fileNameDiv);
    const index = this.fileNameTargets.indexOf(fileNameDiv);
    this.previewTarget.removeChild(fileNameDiv);
    let form = this.fileInputTargets[index].form;
    this.inputDivTarget.removeChild(this.fileInputTargets[index]);
    if (this.fileNameTargets.length == 0) {
      this.previewTarget.classList.remove("px-2", "py-2");
    }
    this.check_validity_form(form);
  }

  toggle_radio_form_elements(event) {
    console.log("toggle_radio_form_elements");
    var element = event.currentTarget;
    if (element.value != "other") {
      var form_element = element.form;
      var form_group_id = element.dataset.formgroupid;
      var all_groups = document.querySelectorAll('[data-grouptargetid]');
      console.log("toggle_radio_form_elements: all_groups", all_groups, form_group_id, form_element);
      all_groups.forEach((element) => {
        if (element.dataset.grouptargetid == form_group_id) {
          element.classList.add('hidden');
        }
      });
      this.disable_submit_button(form_element, false);
      return;
    }
    console.log("toggle_radio_form_elements: other");
    var form_element = element.form;
    var form_group_id = element.dataset.formgroupid;
    var all_groups = document.querySelectorAll('[data-grouptargetid]');
    console.log("toggle_radio_form_elements: all_groups", all_groups, form_group_id, form_element);
    all_groups.forEach((element) => {
      if (element.dataset.grouptargetid == form_group_id) {
        element.classList.remove('hidden');
      }
    });
    this.disable_submit_button(form_element, true);
  }

  set_fabricator_list(event) {

    console.log("get_fabricator_list");
    var element = event.currentTarget;
    var form_group_id = element.dataset.formgroupid;
    var form_element = element.form;
    this.disable_submit_button(form_element, true);

    var target_element;
    var all_groups = document.querySelectorAll('[data-grouptargetid]');
    all_groups.forEach((element) => {
      if (element.dataset.grouptargetid == form_group_id) {
        target_element = element;
      }
    });
    console.log("get_fabricator_list: target_element", target_element);
    if (target_element == undefined) {
      this.check_validity(event);
      return;
    }
    var url = "/fabricators/" + element.value
    let auth_token = document.getElementsByName('csrf-token')[0].getAttribute('content');
    fetch(url, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': auth_token
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data.length == 0) {
          this.process_errors(form_element, ["No Fabricator Available! Please select another type"]);
          this.insert_options(target_element, []);
        } else {
          this.insert_options(target_element, data);
          this.disable_submit_button(form_element, false);
        }
      });
    this.check_validity(event);
  }

  insert_options(element, data) {
    console.log("insert_options", element, data);
    // Remove existing options
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
    // Insert new options
    data.forEach(([text, value]) => {
      const option = document.createElement("option");
      option.text = text;
      option.value = value;
      element.appendChild(option);
    });
  }

  toggle_form_elements(event) {
    var element = event.currentTarget;
    var form_element = element.form;
    var form_group_id = element.dataset.formgroupid;
    var all_groups = document.querySelectorAll('[data-grouptargetid]');
    all_groups.forEach((element) => {
      if (element.dataset.grouptargetid == form_group_id) {
        element.classList.toggle('hidden');
      }
    });
    this.disable_submit_button(form_element, !element.checked);
    this.check_validity(event);
  }

  disable_submit_button(form_element, condition) {
    let submitButton = form_element.querySelector('button[type="submit"]');
    if (condition) {
      submitButton.disabled = true;
    } else {
      submitButton.disabled = false;
    }
  }

  validate_and_submit(event) {
    event.preventDefault();
    console.log("validate_and_submit called");
    this.submit_form = true;
    this.modal_id = event.currentTarget.dataset.modal;
    this.check_validity(event);
  }

  check_validity_form(form_element) {
    this.disable_submit_button(form_element, true);
    let form_data = new FormData(form_element);
    let passes_basic_validation = this.check_basic_validation(form_element);
    if (passes_basic_validation) {
      this.check_data_validity_from_backend(form_element, form_data)
    }
  }

  check_validity(event) {
    let element = event.currentTarget;
    let form_element = element.form;
    this.disable_submit_button(form_element, true);
    let form_data = new FormData(form_element);
    let passes_basic_validation = this.check_basic_validation(form_element);
    console.log("BASIC VALIDATION", passes_basic_validation);
    if (passes_basic_validation) {
      this.check_data_validity_from_backend(form_element, form_data)
    }
  }

  check_basic_validation(form_element) {
    let file_count = 0;
    const image_requiredTarget = form_element.querySelector('input[data-form-target="image_required"]');
    let file_upload_required = (image_requiredTarget && image_requiredTarget.checked) ? true : false;
    console.log("FILE UPLOAD REQD", file_upload_required)
    for (let input of form_element.elements) {
      if (input.type === 'number' && input.value < 0) {
        this.process_errors(form_element, ['Entered value must be atleast 0']);
        this.disable_submit_button(form_element, true);
        return false;
      }
      if (input.type === 'file' && input.files.length > 0) {
        let file_size = (input.files[0].size / (1024 * 1024)).toFixed(2);
        let file_size_limit = 10;
        console.log("FILE SIZE", file_size);
        if (file_size > file_size_limit) {
          this.process_errors(form_element, ['File size must be less than ' + file_size_limit + ' MB']);
          this.disable_submit_button(form_element, true);
          return false;
        }
      }
      if (input.type == 'file')
        file_count += 1;
      if (input.type === 'file' && input.hasAttribute('required') && !input.files.length) {
        this.process_errors(form_element, ['File must be uploaded!']);
        this.disable_submit_button(form_element, true);
        return false;
      }
    }
    if (file_upload_required && file_count == 0) {
      this.process_errors(form_element, ['File must be uploaded!']);
      this.disable_submit_button(form_element, true);
      return false;
    }
    return true;
  }

  check_data_validity_from_backend(form_element, form_data) {
    let auth_token = document.getElementsByName('csrf-token')[0].getAttribute('content');
    let url = form_element.dataset.checkurl;

    let filtered_form_data = new FormData();
    for (let [key, value] of form_data.entries()) {
      if (!(value instanceof File)) {
        filtered_form_data.append(key, value);
      }
    }

    console.log("VALIDITY DATA SENDING TO BACKEND", filtered_form_data);
    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': auth_token
      },
      body: filtered_form_data
    })
      .then(response => response.json())
      .then(data => {
        this.process_errors(form_element, data["errors"]);
        this.process_warnings(form_element, data["warnings"]);
        if (data["errors"].length > 0)
          this.disable_submit_button(form_element, true);
        else {
          if (this.submit_form) {
            form_element.submit();
            if (this.modal_id) {
              Promise.all([
                leave(document.getElementById("backdrop_modal_" + this.modal_id)),
                leave(document.getElementById("bottom_modal_" + this.modal_id))
              ]).then(() => {
                document.getElementById(this.modal_id).classList.add('hidden');
              });
            }
          } else {
            this.disable_submit_button(form_element, false);
          }
        }
      });
  }

  process_errors(form_element, messages) {
    let error_div = form_element.querySelector('div[data-form-target="errors"]');
    let error_body = form_element.querySelector('div[data-form-target="error-body"]');
    if (messages.length > 0) {
      error_body.innerHTML = '';
      messages.forEach((message) => {
        error_body.innerHTML += `
          <div class="flex flex-row w-full gap-1">
            <svg class="self-center w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
              <path fill-rule="evenodd" d="M16.72 7.72a.75.75 0 011.06 0l3.75 3.75a.75.75 0 010 1.06l-3.75 3.75a.75.75 0 11-1.06-1.06l2.47-2.47H3a.75.75 0 010-1.5h16.19l-2.47-2.47a.75.75 0 010-1.06z" clip-rule="evenodd" />
            </svg>
            <div class="self-center"> ${message} </div>
          </div>
        `
      });
      error_div.classList.remove('hidden');
    } else {
      error_div.classList.add('hidden');
    }
  }

  process_warnings(form_element, messages) {
    let warning_div = form_element.querySelector('div[data-form-target="warnings"]');
    let warning_body = form_element.querySelector('div[data-form-target="warning-body"]');
    if (messages.length > 0) {
      warning_body.innerHTML = '';
      messages.forEach((message) => {
        warning_body.innerHTML += `
          <div class="flex flex-row w-full gap-1">
            <svg class="self-center w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
              <path fill-rule="evenodd" d="M16.72 7.72a.75.75 0 011.06 0l3.75 3.75a.75.75 0 010 1.06l-3.75 3.75a.75.75 0 11-1.06-1.06l2.47-2.47H3a.75.75 0 010-1.5h16.19l-2.47-2.47a.75.75 0 010-1.06z" clip-rule="evenodd" />
            </svg>
            <div class="self-center"> ${message} </div>
          </div>
        `
      });
      warning_div.classList.remove('hidden');
    } else {
      warning_div.classList.add('hidden');
    }
  }
}

