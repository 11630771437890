import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['modal', 'iframe'];

  connect() {
    console.log('pdf viewer controller connected');
  }

  open(event) {
    let element = event.currentTarget;
    let pdf_name = element.dataset.modal;
    let internal = element.dataset.internal;
    document.getElementById(pdf_name).classList.remove('hidden')
    let iframe_target_name = "iframe_" + pdf_name;
    let loader_target_name = "loader_" + pdf_name;
    let iframe_element = document.getElementById(iframe_target_name);
    let loader_element = document.getElementById(loader_target_name);
    let url = "/pdf/internal/" + pdf_name;
    if(internal == "false")
      url = "/pdf/external/" + pdf_name;
    iframe_element.setAttribute('src', url);
    iframe_element.onload = () => {
      console.log('iframe loaded');
      loader_element.classList.add('hidden')
    };
  }

  close(event) {
    let element = event.currentTarget;
    let pdf_name = element.dataset.modal;
    let iframe_target_name = "iframe_" + pdf_name;
    let loader_target_name = "loader_" + pdf_name;
    let iframe_element = document.getElementById(iframe_target_name);
    iframe_element.src = ""
    let loader_element = document.getElementById(loader_target_name);
    loader_element.classList.remove('hidden')
    document.getElementById(pdf_name).classList.add('hidden')
  }
}
