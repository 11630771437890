import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log('pdf viewer controller connected');
  }

  open(event) {
    console.log('IFRAME open called');
    let element = event.currentTarget;
    let modal = element.dataset.modal;
    let url_to_open = element.dataset.url;
    document.getElementById(modal).classList.remove('hidden')
    let iframe_target_name = "iframe_" + modal;
    let loader_target_name = "loader_" + modal;
    let iframe_element = document.getElementById(iframe_target_name);
    let loader_element = document.getElementById(loader_target_name);
    iframe_element.setAttribute('src', url_to_open);
    iframe_element.onload = () => {
      console.log('iframe loaded');
      loader_element.classList.add('hidden')
    };
  }

  close(event) {
    console.log('IFRAME close called');
    let element = event.currentTarget;
    let modal = element.dataset.modal;
    let iframe_target_name = "iframe_" + modal;
    let loader_target_name = "loader_" + modal;
    let iframe_element = document.getElementById(iframe_target_name);
    iframe_element.src = ""
    let loader_element = document.getElementById(loader_target_name);
    loader_element.classList.remove('hidden')
    document.getElementById(modal).classList.add('hidden')
  }
}
