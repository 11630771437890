import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "submit_button", "error_div", "error_body",
    "warning_div", "warning_body",
    "task_form_select", "task_form_input", "task_form_body"
  ]

  connect() {
    console.log("stimulus-connected: task_controller.js")
  }

  check_validity(event) {
    console.log("calling!");
    var data = this.getData();
    var select_data = this.get_select_data();
    var errors = [];
    var values = this.get_data_values(data);
    this.process_warning('');
    Object.keys(values).forEach((key) => {
      if(isNaN(values[key])) {
        errors.push("Quantity must be a valid number");
      }
    });
    if(errors.length > 0) {
      this.process_error(errors);
      return;
    }
    let auth_token = document.getElementsByName('csrf-token')[0].getAttribute('content');
    var url = this.task_form_bodyTarget.dataset.checkurl;
    var body = { details: {...data["details"], ...select_data["details"]} };
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': auth_token
      },
      body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => {
      this.process_error(data["errors"]);
      this.process_warning(data["warning"]);
    })
    .catch(error => {
      this.error_bodyTarget.innerHTML = ''
      this.error_divTarget.classList.add('hidden');
    });
  }

  process_warning(data) {
    if(data.length > 0) {
      this.warning_bodyTarget.innerHTML = `
        <div class="flex flex-row w-full gap-1 p-1">
          <svg class="self-center w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
            <path fill-rule="evenodd" d="M16.72 7.72a.75.75 0 011.06 0l3.75 3.75a.75.75 0 010 1.06l-3.75 3.75a.75.75 0 11-1.06-1.06l2.47-2.47H3a.75.75 0 010-1.5h16.19l-2.47-2.47a.75.75 0 010-1.06z" clip-rule="evenodd" />
          </svg>
          <div class="self-center"> ${data} </div>
        </div>
      `
      this.warning_divTarget.classList.remove('hidden')
    } else {
      this.warning_bodyTarget.innerHTML = ''
      this.warning_divTarget.classList.add('hidden')
    }
  }

  process_error(data) {
    if(data.length > 0) {
      this.submit_buttonTargets.forEach((button) => {
        button.disabled = true;
      });
      console.log("HERE", data);
      this.error_bodyTarget.innerHTML = ''
      Object.keys(data).forEach((key) => {
        console.log("DATUM", key);
        this.error_bodyTarget.innerHTML += `
          <div class="flex flex-row w-full gap-1 p-1">
            <svg class="self-center w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
              <path fill-rule="evenodd" d="M16.72 7.72a.75.75 0 011.06 0l3.75 3.75a.75.75 0 010 1.06l-3.75 3.75a.75.75 0 11-1.06-1.06l2.47-2.47H3a.75.75 0 010-1.5h16.19l-2.47-2.47a.75.75 0 010-1.06z" clip-rule="evenodd" />
            </svg>
            <div class="self-center"> ${data[key]} </div>
          </div>
        `
      })
      this.error_divTarget.classList.remove('hidden')
    } else {
      this.error_bodyTarget.innerHTML = ''
      this.error_divTarget.classList.add('hidden')
      this.submit_buttonTargets.forEach((button) => {
        button.disabled = false;
      });
    }
  }

  get_select_data() {
    var data = {};
    this.task_form_selectTargets.forEach((input) => {
      data[input.name] = input.value;
    });
    return this.simple_convert(data);
  }

  simple_convert(obj) {
    let converted = {};

    for (let key in obj) {
      let value = obj[key];
      let currentLevel = converted;
      let keys = key.split(/[\[\]]+/).filter(Boolean);

      keys.forEach((k, i) => {
        if (i === keys.length - 1) {
          currentLevel[k] = value;
        } else {
          if (!(k in currentLevel)) {
            currentLevel[k] = {};
          }
          currentLevel = currentLevel[k];
        }
      });
    }

    return converted;
  }

  getData() {
    var data = {};
    this.task_form_inputTargets.forEach((input) => {
      data[input.name] = input.value;
    });
    return this.convertObject(data);
  }

  convertObject(obj) {
    let converted = {};

    for (let key in obj) {
      let value = obj[key];
      let currentLevel = converted;
      let keys = key.split(/[\[\]]+/).filter(Boolean);

      keys.forEach((k, i) => {
        if (i === keys.length - 1) {
          currentLevel[k] = parseFloat(value);
        } else {
          if (!(k in currentLevel)) {
            currentLevel[k] = {};
          }
          currentLevel = currentLevel[k];
        }
      });
    }

    return converted;
  }



  get_data_values(obj) {
    let values = [];
    for (let key in obj) {
      if (typeof obj[key] === "object") {
        values = values.concat(this.get_data_values(obj[key]));
      } else {
        values.push(obj[key]);
      }
    }
    return values;
  }
}
