import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs"

export default class extends Controller {
  connect() {
    console.log("connected mention controller")
    this.tribute = new Tribute({
      collection: [{
        trigger: '@',
        selectClass: 'bg-indigo-100',
        containerClass: 'absolute bg-white shadow-lg rounded-lg overflow-hidden border border-gray-300 w-32',
        itemClass: 'px-3 py-2 border-b border-gray-300 last:border-0 cursor-pointer',
        noMatchTemplate: function () {
          return '<span style:"visibility: hidden;"></span>';
        },
        selectTemplate: (item) => `@${item.original.username}:`,
        menuItemTemplate: (item) => {
          return `
            <div class="flex items-center">
              <span class="text-white font-medium antialiased flex w-6 items-center justify-center rounded-full bg-blue-900/80 ring-1 ring-white">${item.original.name[0]}</span>
              <span class="font-normal text-base antialiased ml-3 block truncate">${item.original.name}</span>
            </div>
          `
        },
        values: (text, cb) => this.fetchUserSuggestions(text, cb),
        lookup: 'username',
        fillAttr: 'username'
      }]
    })
    this.tribute.attach(this.element)
  }

  async fetchUserSuggestions(text, cb) {
    const response = await fetch(`/users/suggest?query=${text}`);
    const records = await response.json();
    console.log(records)
    const items = records.map(record => ({ username: record.username, name: record.name }));
    cb(items);
  }
}
