import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["filter_item", "filter_submit_button"]

  connect() {
    console.log("filter attached")
  }

  refresh() {
    this.filter_itemTargets.forEach((checkbox) => {
      checkbox.checked = false;
    });
    this.filter_submit_buttonTarget.click();
  }
}
