import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["flash"]

  connect() {
    this.show_flash()
    setTimeout(() => this.hide_flash(), 10000)
  }

  show_flash() {
    this.flashTarget.classList.remove('hidden')
  }

  hide_flash() {
    this.flashTarget.classList.add('hidden')
  }
}
