import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
import Dropdown from 'stimulus-dropdown'
import Timeago from 'stimulus-timeago'
import Lightbox from 'stimulus-lightbox'
import Quagga from 'quagga';
import '@hotwired/turbo-rails'
import "tributejs/dist/tribute.css"
import Tribute from "tributejs"
import 'pica';

const application = Application.start()
const controllers = import.meta.globEager('./**/*_controller.js')
registerControllers(application, controllers)
application.register('dropdown', Dropdown)
application.register('timeago', Timeago)
application.register('lightbox', Lightbox)
application.register('quagga', Quagga)
application.register('tributejs', Tribute)



